// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../library/frontend/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../library/frontend/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../library/frontend/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-SemiBoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {

    font-family: 'IBM Plex Sans Condensed';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {

    font-family: 'IBM Plex Sans Condensed';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {

    font-family: 'IBM Plex Sans Condensed';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {

    font-family: 'IBM Plex Sans Condensed';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
`, "",{"version":3,"sources":["webpack://./../../ui/styles/IBM_Plex_Sans_Condensed.css"],"names":[],"mappings":"AAAA;;IAEI,sCAAsC;IACtC,+DAA6F;IAC7F,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;;IAEI,sCAAsC;IACtC,+DAA8F;IAC9F,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;;IAEI,sCAAsC;IACtC,+DAA4F;IAC5F,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;;IAEI,sCAAsC;IACtC,+DAAoG;IACpG,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["@font-face {\n\n    font-family: 'IBM Plex Sans Condensed';\n    src: url('fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Regular.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n\n    font-family: 'IBM Plex Sans Condensed';\n    src: url('fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-SemiBold.ttf') format('truetype');\n    font-weight: bold;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n\n    font-family: 'IBM Plex Sans Condensed';\n    src: url('fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Italic.ttf') format('truetype');\n    font-weight: normal;\n    font-style: italic;\n    font-display: swap;\n}\n\n@font-face {\n\n    font-family: 'IBM Plex Sans Condensed';\n    src: url('fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-SemiBoldItalic.ttf') format('truetype');\n    font-weight: bold;\n    font-style: italic;\n    font-display: swap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
